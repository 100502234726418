@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.App{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.songs_list{
    
}