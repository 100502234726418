.playerPageDiv{
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        /* align-items: center; */
        padding-top:1rem;
        flex-wrap: wrap;
        width:80%;
}

.songList{
        /* width:80%; */
        margin-top: 2rem;
}


.player{
        width: 80%;
        /* height: 100vh; */
        display: flex;
        justify-content: center;
        /* border:1px solid red; */
        align-items: center;
        flex-direction: column;
}

.list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top:1rem;
      }
      
      .list-item {
        display: flex;
        align-items: center;
        padding: 5px; /* Optional: Add some padding for better visibility of the hover effect */
        transition: background-color 0.3s ease; /* Smooth transition effect */
      }
      
      .list-item:hover {
        background-color: lightgray; /* Change to your preferred hover color */
      }
      
      .play-pause-button {
        background: none;
        border: none;
        cursor: pointer;
        margin-right: 10px; /* Space between button and song title */
      }

      .download-button {
        background: none;
        border: none;
        cursor: pointer;
        margin-left: auto; /* Push download button to the far right */
      }
      
      .play-pause-button:focus,
      .download-button:focus {
        outline: none;
      }

      .download-button:active {
        transform: scale(0.95);
        background-color: rgba(0, 0, 0, 0.1);
      }
      
      