.player_container
{
    width: 100%;
    padding: 1rem;
    border: 1px solid rgb(141, 141, 141);
    border-radius: 10px;
    color: rgb(218, 218, 218);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // background-color: black;
    background-image: url("https://images.unsplash.com/photo-1594623930572-300a3011d9ae?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;

    >*
    {
        padding: 1rem 0;
        color: rgb(202, 202, 202);
    }

    .title{
        font-size: inherit;
    }
    .navigation{
        width: 100%;


        .navigation_wrapper
        {
            min-width: 100%;
            background-color: rgba(119, 119, 119, 0.781);
            height: 5px;
            border-radius: 30px;
            cursor: pointer;
            
            .seek_bar{
                width: 0;
                height: 100%;
                background-color: rgb(24, 128, 3);
                border-radius: 30px;
            }

        }
    }
    .controls{
        font-size: inherit;
        display: flex;
        align-items: center;

        .btn_action
        {
            font-size: 2rem;
            margin: 0 1rem;
            cursor: pointer;

            &:hover{
            color: white;
        }

        }
        
        .pp
        {
            font-size: 4rem;
            
        }
        
    }
}